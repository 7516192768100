<template>
    <v-col cols="12" sm="6" md="4">
        <v-hover>
            <template v-slot:default="{ isHovering, props }"> 
                <v-card 
                    v-bind="props" 
                    :color="isHovering ? 'grey-lighten-3' : undefined" 
                    :prepend-icon="icon"
                    @click="navigateTo(path_to)">

                    <!-- Título do Card -->
                    <template v-slot:title>
                        <span class="font-weight-black">{{ title }}</span>
                    </template>

                    <!-- Descrição do Card -->
                    <v-card-text class="bg-surface-light pt-4 toolscard-text">
                        {{ description }}
                    </v-card-text>

                    <!-- Botão para acessar a página JSON Tools -->
                    <v-card-actions>
                        <v-btn variant="tonal" size="small" color="deep-purple-lighten-1" :to="path_to"> Acessar </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    name: 'HomeCardTools',
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        path_to: {
            type: String,
            required: true
        }
    },
    methods: {
        navigateTo(path) {
            this.$router.push(path);
        }
    }
};
</script>

<style lang="scss" scoped>
.toolscard-text {
    min-height: 130px;
}
</style>