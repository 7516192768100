<template>
    <div>
        <v-app-bar color="blue-grey-lighten-5 topbar" density="compact" elevation="0" app class="mb-3">
            <!-- 
                Ícone do menu hamburguer para dispositivos móveis 
                só é exibido em telas menores
            -->
            <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>

            <!-- Título ou logo -->
            <v-toolbar-title class="app_title">
                <a href="/">NerdTools</a>
            </v-toolbar-title>

            <!-- Espaço entre o título e os botões de navegação -->
            <v-spacer></v-spacer>
            <!-- Botões de navegação visíveis em telas maiores -->
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-for="item in menu_items" :key="item.title" plain :to="item.path">
                    <v-icon>{{ item.icon }}</v-icon> {{ item.title }}
                    <v-menu v-if="item.subitems" activator="parent">
                        <v-list>
                            <v-list-item v-for="subitem in item.subitems" :key="subitem.title" :to="subitem.path">
                                <v-list-item-title><v-icon>{{ subitem.icon }}</v-icon> {{ subitem.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <!-- Menu lateral para dispositivos móveis -->
        <v-navigation-drawer v-model="drawer" app temporary>
            <v-list nav dense class="text-left">
                <span v-for="item in menu_items" :key="item.title">
                    <v-list-item v-if="!item.subitems" :prepend-icon="item.icon" :title="item.title" link :to="item.path"/>
                    <v-list-group v-else class="mobile_tools_menu">
                        <template v-slot:activator="{ props }">
                            <v-list-item :prepend-icon="item.icon" :title="item.title" v-bind="props" />
                        </template>                    
                        <v-list-item v-for="subitem in item.subitems" :key="subitem.title" :prepend-icon="subitem.icon" :title="subitem.title" link :to="subitem.path"/>
                    </v-list-group>
                </span>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
data() {
    return {
        drawer: false, // Controla o estado do menu lateral
        menu_items: [
            { title: 'Home', icon: 'mdi-home', path: '/' },
            { title: 'Tools', icon: 'mdi-cog', path: '/tools', 
                subitems: [
                    { title: 'JSON Tools', icon: 'mdi-code-braces', path: '/jsontools' },
                    { title: 'Timestamp Tools', icon: 'mdi-clock-time-eight-outline', path: '/timestamp' },
                    { title: 'Base64 Tools', icon: 'mdi-xml', path: '/base64' }
                ] 
            },
            { title: 'Tutoriais', icon: 'mdi-school', path: '/tutoriais' },
            { title: 'About', icon: 'mdi-information', path: '/about' }
        ]
    };
}
};
</script>
  
<style lang="scss" scoped>
.mobile_tools_menu{
    --list-indent-size: 5px !important;
    padding-inline-start: none !important;
}
.topbar{
    border-bottom: solid 1px #eaeaea;
}
.app_title{
    text-align: left;
}
.app_title a{
    text-decoration: none;
    color: #333;
}
  
  // nav {
  //   padding: 30px;
  //   border-bottom: solid 1px #ccc;
  
  //   a {
  //     font-weight: bold;
  //     color: #2c3e50;
  //     text-decoration: none;
  //     padding: 2px 10px 2px 10px;
  //     height: 20px;
      
  //     &.router-link-exact-active {
  //       color: #42b983;
  //     }
  //   }
  // }
</style>
  