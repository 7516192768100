<template> 
  <v-container>
    <div class="hello">
      <img class="image_home" src="../assets/people-at-work.gif">

      <h1 class="pb-3">Bem-vindo</h1>
      <p class="text-center">
        Sua plataforma para ferramentas rápidas e eficientes, projetadas para facilitar o desenvolvimento de software.
      </p>
    </div>
   
    <div class="card_tools mt-2">
     <HomeCardTools v-for="card in cardsTools" :key="card.title" :icon="card.icon" :title="card.title" :description="card.description" :path_to="card.path_to" />        
    </div>
    
  </v-container>

</template>

<script>
// @ is an alias to /src
import HomeCardTools from '@/components/HomeCardTools.vue'

export default {
  name: 'HomeView',
  components: {
    HomeCardTools
  },
  data() {
    return {
      cardsTools: [
        {
          icon: 'mdi-code-json',
          title: 'JSON Tools',
          description: 'Formate, minifique, valide e converta JSON para string ou vice-versa. Ferramentas simples e eficazes para lidar com dados estruturados',
          path_to: '/jsontools'
        },
        {
          icon: 'mdi-xml',
          title: 'BASE64 Tools',
          description: 'Realize operações essenciais com BASE64, como codificação e decodificação de strings.',
          path_to: '/base64'
        },
        {
          icon: 'mdi-clock-outline',
          title: 'Timestamp Tools',
          description: 'Converta timestamps para datas legíveis, gere timestamps em tempo real, e faça a conversão reversa entre formatos de tempo.',
          path_to: '/timestamp'
        }
      ]       
    }
  },
  metaInfo() {
    return {
      title: 'NerdTools - Fast Tools for Developers',
      meta: [
        {
          name: 'description',
          content: 'Platform offering fast tools for developers, including JSON formatting, BASE64 conversion, and timestamp generation.'
        },
        {
          name: 'keywords',
          content: 'developer tools, JSON, BASE64, timestamps, development, utilities'
        }
      ]
    };
  },
  
  created() {
    this.$gtag.pageview('Home');
  }
}
</script>
<style scoped lang="scss">
.hello {
  text-align: center;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.card_tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image_home{
  max-width: 300px;
}

</style>
