<template>
  <v-app class="page">
    <TopBar />
    <router-view class="content"/>
    
    <v-spacer></v-spacer>
    
    <!-- Footer --> 
    <v-footer class="rodape text-center mt-5 bg-surface-light" >
      <span>&copy; {{ new Date().getFullYear() }} NerdTools | 
      <a href="https://linkedin.com/in/ismaeldiniz" target="_blank"><v-icon color="black darken-2">mdi-linkedin</v-icon>by Ismael Diniz</a>
      </span>
    </v-footer>

  </v-app>
</template>
<script>
import TopBar from './components/TopBar.vue'
export default {
  name: 'App',
  components: {
    TopBar
  },
  data() {
    return {
      drawer: false, // Controla o estado do menu lateral
    };
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.content{
  margin-top: 30px;
  max-width: 950px;
}

.page{
  background: #fcfcfc;
}

.rodape{
  color: #2c3e50;
  font-size: 0.85rem;
  font-weight: bold;
  height: 50px !important;
  text-align: end;
  a {
    color: #42b983;
  }
}
</style>
