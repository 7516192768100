
// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import VueGtag from 'vue-gtag';

// Components
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi',  // Define 'mdi' como o conjunto de ícones padrão
  },
})

createApp(App).use(router).use(vuetify).use(VueGtag, {
  config: {
    id: "G-3LDZTC87ZG"
  }
}).mount('#app')
